import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import { setConfirmationToken } from '@cjm/shared/utils';
import { AssociationApiService, IMainActivity } from '@cjm/v-loket/repositories';

import { IPostRegistrationPayload, IPostRegistrationPayloadNL, PostRegistrationResponseEntity } from '../../interfaces';

@Injectable()
export class RegisterAPIService {
	constructor(
		private readonly httpClient: CJMHttpClientService,
		private readonly associationApiService: AssociationApiService
	) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 * postRegistrationData
	 *
	 * The postRegistrationData method performs a POST request to the API on the /vereniging endpoint.
	 * It takes in the payload formatted to the IPostAssociationPayload.
	 * Return type is set Observable<IPostRegistrationResponse> which is a translated version of the API response.
	 *
	 * @param data IPostAssociationPayload
	 * @param token string
	 * @returns Observable:PostRegistrationResponseEntity
	 */
	public postRegistrationData(
		data: IPostRegistrationPayload,
		token?: string
	): Observable<PostRegistrationResponseEntity> {
		return this.httpClient.post(
			VLoketEndpoints.Associations.PostAssociation(),
			this.translatePostRegistrationPayload(data),
			token ? setConfirmationToken(token) : null
		);
	}

	/**
	 * getMainActivities
	 *
	 * The getMainActivities method exposes the getMainActivities method from the AssociationApiService
	 *
	 * @returns Observable:IMainActivities
	 */
	public getMainActivities(): ObservableArray<IMainActivity> {
		return this.associationApiService.getMainActivities();
	}

	/**
	 * translatePostRegistrationPayload
	 *
	 * The translatePostRegistrationPayload method translates the payload to NL to match the API specs.
	 *
	 * @param data IPostAssociationPayload
	 * @returns IPostAssociationPayloadNL
	 */
	private translatePostRegistrationPayload({
		basicInfo,
		representative,
		invites,
		sendCopy,
		subscribeToNewsletter
	}: IPostRegistrationPayload): IPostRegistrationPayloadNL {
		return {
			naam: basicInfo.associationName,
			correspondentieadres: {
				straat: basicInfo.associationAddress.street,
				nummer: basicInfo.associationAddress.number,
				bus: basicInfo.associationAddress.box,
				postcode: basicInfo.associationAddress.zipcode,
				gemeente: basicInfo.associationAddress.locality,
				land: basicInfo.associationAddress.country
			},
			hoofdvertegenwoordigers: [
				{
					emailadres: representative.representativeEmailField,
					telefoonnummer: representative.representativePhoneField,
					inschrijvenOpNieuwsbrief: Boolean(subscribeToNewsletter)
				}
			],
			uitnodigingen: Object.values(invites).map((representative) => ({
				voornaam: representative.representativeFirstnameField,
				achternaam: representative.representativeSurnameField,
				emailadres: representative.representativeEmailField,
				telefoonnummer: representative.representativePhoneField,
				insz: representative.representativeRRNField.replace(/\D/g, ''),
				kopieEmailadres: !sendCopy.isActive ? null : sendCopy.recipient
			})),
			hoofdactiviteiten: Object.keys(basicInfo.mainActivities).reduce(
				(acc, key: string) => (basicInfo.mainActivities[key] ? [...acc, key] : acc),
				[]
			)
		};
	}
}
