@if (registerForm) {
	<form class="c-vloket-form" [formGroup]="registerForm">
		<cjm-layout-container layoutType="split-equal" asidePosition="left" preventSpacing="bottom">
			<ng-container asideLeft>
				<h2 class="c-vloket-header c-vloket-header--h1">
					<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
						{{ i18nKeys.Registration.Register.BasicData.Title | translate }}
					</span>
				</h2>
				<p
					class="c-vloket-text--lg"
					[innerHTML]="i18nKeys.Registration.Register.BasicData.Description | translate"
				></p>
			</ng-container>
			<ng-container main>
				@if (mainActivities(); as data) {
					<vloket-basic-registration-data-form
						#formAccessor
						formControlName="basicInfo"
						[data]="data"
						(initialized$)="basicDataFormInitialized($event)"
					></vloket-basic-registration-data-form>
				}
			</ng-container>
		</cjm-layout-container>
		<cjm-layout-container
			class="p-register__representative-container"
			layoutType="split-equal"
			asidePosition="left"
			[preventSpacing]="['top', 'bottom']"
		>
			<ng-container asideLeft>
				<h2 class="c-vloket-header c-vloket-header--h1">
					<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
						{{ i18nKeys.Registration.Register.RepresentativeData.Title | translate }}
					</span>
				</h2>
				<p class="c-vloket-text--s c-vloket-text--light u-margin-bottom-sm">
					<fa-icon icon="fa-eye-slash" iconType="fa-regular" />
					{{ i18nKeys.Registration.Register.RepresentativeData.Tooltip | translate }}
				</p>
				<p
					class="c-vloket-text--lg"
					[innerHTML]="i18nKeys.Registration.Register.RepresentativeData.Description | translate"
				></p>
			</ng-container>
			<ng-container main>
				<vloket-representative-form
					#formAccessor
					formControlName="representative"
					[titleOverwrite]="i18nKeys.Registration.Register.RepresentativeData.YourDataTitle | translate"
					[disableFields]="[
						'representativeFirstnameField',
						'representativeSurnameField',
						'representativeRRNField'
					]"
				></vloket-representative-form>
			</ng-container>
			<ng-container main>
				<div class="u-margin-top-xl" [formGroup]="registerForm | getFormGroup: 'invites'">
					@for (key of inviteKeys(); track key; let index = $index; let last = $last) {
						<vloket-representative-form
							#formAccessor
							[formControlName]="key"
							[index]="index"
							[mailOnly]="true"
							[key]="key"
							[isDuplicate]="registerForm.hasError('duplicateINSZError') && duplicateInviteKeys()"
						></vloket-representative-form>
						<div class="p-register__representative_actions">
							@if (index >= 1) {
								<cjm-button
									type="button"
									classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }}"
									[title]="
										i18nKeys.Registration.Register.RepresentativeData.Actions.RemoveRepresentative
											.Title | translate
									"
									(handleClick)="removeRepresentative(key)"
								>
									<fa-icon icon="fa-trash-can" />
									{{
										i18nKeys.Registration.Register.RepresentativeData.Actions.RemoveRepresentative
											.Text | translate
									}}
								</cjm-button>
							}
							<div class="button-with-info">
								@if (last) {
									<cjm-button
										type="button"
										classes="{{ buttonClasses.LinkNoDecoration }} {{ buttonClasses.IconLeft }} {{
											last && index < 3 ? '' : buttonClasses.Disabled
										}}"
										[title]="
											i18nKeys.Registration.Register.RepresentativeData.Actions.AddRepresentative
												.Title | translate
										"
										(handleClick)="last && index < 3 ? addRepresentative() : null"
									>
										<fa-icon icon="fa-plus" />
										{{
											i18nKeys.Registration.Register.RepresentativeData.Actions.AddRepresentative
												.Text | translate
										}}
									</cjm-button>
								}
								<!--
                If a tooltip description is provided for a disabled delete,
                do not hide the action, but show the tooltip instead
                ~ Denis
                -->
								@if (last && index >= 3) {
									<cjm-info
										class="u-margin-left"
										[description]="
											i18nKeys.Registration.Register.RepresentativeData.Actions.AddRepresentative
												.Tooltip | translate
										"
									/>
								}
							</div>
						</div>
					}
				</div>
			</ng-container>
		</cjm-layout-container>
		<cjm-layout-container
			layoutType="split-equal"
			asidePosition="left"
			preventSpacing="top"
			classesMain="u-margin-top-lg"
		>
			<vloket-send-copy main formControlName="sendCopy" />
		</cjm-layout-container>
		<cjm-layout-container layoutType="split-equal" asidePosition="left" [preventSpacing]="['top', 'bottom']">
			<ng-container asideLeft>
				<h2 class="c-vloket-header c-vloket-header--h1">
					<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
						{{ i18nKeys.Registration.Register.NewsLetterData.Title | translate }}
					</span>
				</h2>
				<p
					class="c-vloket-text--lg"
					[innerHTML]="i18nKeys.Registration.Register.NewsLetterData.Description | translate"
				></p>
			</ng-container>
			<ng-container main>
				<h3 class="c-vloket-header c-vloket-header--h3">
					<span class="c-vloket-text-marking c-vloket-text-marking--line-through">
						{{ i18nKeys.Registration.Register.NewsLetterData.SubscribeToNewsletter.Title | translate }}
					</span>
				</h3>
				<fieldset>
					<cjm-checkbox
						formControlName="subscribeToNewsletter"
						[title]="
							i18nKeys.Registration.Register.NewsLetterData.SubscribeToNewsletter.Checkbox.Title
								| translate
						"
					>
						{{
							i18nKeys.Registration.Register.NewsLetterData.SubscribeToNewsletter.Checkbox.Text
								| translate
						}}
					</cjm-checkbox>
				</fieldset>
			</ng-container>
		</cjm-layout-container>
		<cjm-layout-container layoutType="split-equal" asidePosition="left" preventSpacing="top">
			<ng-container main>
				@if (errorDetails(); as errorDetails) {
					<cjm-alert
						alertType="is-error"
						[alertTitle]="i18nKeys.FormErrorDetails.ErrorDetailAlertTitle | translate"
						[alertMessage]="errorDetails"
						[isClosable]="false"
					/>
				}
				<div class="c-vloket-form__actions">
					<cjm-button
						cypressTag="FVRegistration.Actions.Submit"
						[title]="i18nKeys.Registration.Register.FormActions.RegisterAssociation.Title | translate"
						[classes]="buttonClasses.LinkButton"
						[isDisabled]="actionInProgress()"
						(handleClick)="submitRegistration()"
					>
						{{ i18nKeys.Registration.Register.FormActions.RegisterAssociation.Text | translate }}
					</cjm-button>
					<cjm-button
						[classes]="buttonClasses.LinkButtonOutline"
						[title]="i18nKeys.Forms.Actions.Cancel.Title | translate"
						[isDisabled]="actionInProgress()"
						[routerLink]="['..', this.appRoutePaths.RegistrationStart]"
					>
						{{ i18nKeys.Forms.Actions.Cancel.Text | translate }}
					</cjm-button>
				</div>
			</ng-container>
		</cjm-layout-container>
	</form>
}
