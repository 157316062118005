import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { validateContent } from '@studiohyperdrive/rxjs-utils';
import { catchError, Observable, of, retry, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseLocationString } from '@cjm/shared/utils';
import { AssociationApiService } from '@cjm/v-loket/repositories';
import { AssociationDetailEntity, mainLocationSelector } from '@cjm/v-loket/shared';

import { IEARegisterFormDataEntity, IRegisterFormDataEntity, IRegisterAssociationData } from '../../interfaces';
import { actions, selectors } from '../../store/registration.store';

export const successPageResolver = (route: ActivatedRouteSnapshot): Observable<IRegisterAssociationData> => {
	const store = inject(Store);
	const associationApiService = inject(AssociationApiService);
	const router = inject(Router);

	const vCode = route?.params?.vCode;

	if (!vCode) {
		router.navigate(['/not-found']);
		return of(null);
	}

	const registerFormData$: Observable<IRegisterFormDataEntity | IEARegisterFormDataEntity> = store.pipe(
		select(selectors.registerFormData.select)
	);

	return dispatchDataToStore(
		actions.associationData,
		associationApiService.getAssociationDetail(vCode).pipe(
			retry({ count: 5, delay: 500 }),
			catchError(() =>
				registerFormData$.pipe(
					map(
						(formData: IRegisterFormDataEntity | IEARegisterFormDataEntity) =>
							({
								associationNumber: vCode,
								names: { full: (formData as IRegisterFormDataEntity)?.basicInfo?.associationName },
								associationType: { code: formData.basicInfo.associationType }
							}) as Partial<AssociationDetailEntity>
					)
				)
			),
			validateContent(),
			map((detail: AssociationDetailEntity) => ({
				id: detail.associationNumber,
				organisationId: detail.organisationNumber,
				name: detail.names.full,
				type: detail.associationType.description,
				labels: (Array.isArray(detail.mainActivities) ? detail.mainActivities : []).map(({ name }) => name),
				location: parseLocationString(mainLocationSelector(detail.locations)),
				representatives: []
			}))
		),
		store
	).pipe(
		take(1),
		catchError(() => {
			router.navigate(['/not-found']);
			return of(null);
		})
	);
};
