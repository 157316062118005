import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { camelCase } from 'lodash';

import { AlertType, VloketLink } from '@cjm/shared/ui/common';
import { generateSnackbarConfig } from '@cjm/shared/ui/toast';

import { NotificationLinkResultEntity, NotificationResultEntity } from '../../interfaces';

/**
 * parseNotificationLink
 *
 * The parseNotificationLink helper will parse a NotificationLinkResultEntity to a VloketLink
 *
 * @param link{NotificationLinkResultEntity}
 * @returns VloketLink
 */
export const parseNotificationLink = (link: NotificationLinkResultEntity): VloketLink => {
	if (!link) {
		return;
	}

	return {
		title: link.naam,
		text: link.naam,
		type: 'external',
		url: link.actieLink
	};
};

/**
 * parseNotificationSeverity
 *
 * The parseNotificationSeverity helper will parse the provided severity to an AlertType value
 *
 * @param severity{'info' | 'error'}
 * @returns AlertType
 */
export const parseNotificationSeverity = (severity: 'info' | 'error'): AlertType => {
	if (severity === 'error') {
		return AlertType.Error;
	}

	return AlertType.Info;
};

/**
 * parseNotificationResults
 *
 * The parseNotificationResults helper will parse snackbar configs based on the provided notifications
 *
 * @param notifications{NotificationResultEntity[]}
 * @returns MatSnackBarConfig & { id: string }[]
 */
export const parseNotificationResults = (
	notifications: NotificationResultEntity[] = []
): MatSnackBarConfig & { id: string }[] => {
	if (!notifications || !Array.isArray(notifications)) {
		return [];
	}

	const notificationCount = notifications.length;

	return notifications.map((notification: NotificationResultEntity, index: number) => {
		const id = camelCase(notification.wijzigingsdatum + notification.naam);

		return {
			id,
			...generateSnackbarConfig({
				id,
				title:
					notificationCount > 1
						? `${notification.naam} (${index + 1}/${notificationCount})`
						: notification.naam,
				message: notification.bericht,
				type: parseNotificationSeverity(notification.ernst),
				links: [parseNotificationLink(notification.link)],
				persistClosure: 'local',
				duration: null
			}),
			horizontalPosition: 'right',
			verticalPosition: 'bottom'
		};
	});
};
