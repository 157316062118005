import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@cjm/shared/store';

import { NotificationApiService } from './services';
import { notificationReducers } from './store';

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Notifications, notificationReducers)],
	providers: [NotificationApiService]
})
export class NotificationsDataModule {}
