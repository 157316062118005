@if (initialized$ | async) {
	<fieldset [formGroup]="form">
		<fieldset class="c-vloket-form__fieldset">
			<legend class="c-vloket-header c-vloket-header--h4">
				<span class="c-vloket-text-marking c-vloket-text-marking--underline">
					{{ i18nKeys.Registration.Register.BasicData.GeneralFieldset.Title | translate }}
				</span>
			</legend>
			<fieldset class="c-vloket-form__fieldset__subset--split-equal">
				<cjm-input-wrapper
					id="associationName"
					[label]="
						i18nKeys.Registration.Register.BasicData.GeneralFieldset.AssociationNameField.Label | translate
					"
				>
					<input
						#input
						type="text"
						formControlName="associationName"
						cypressTag="BasicRegistrationDataForm.AssociationName.Input"
						[placeholder]="
							i18nKeys.Registration.Register.BasicData.GeneralFieldset.AssociationNameField.Placeholder
								| translate
						"
					/>
					<ng-container description ngxErrors="associationName">
						<div ngxError="required" showWhen="dirty">
							{{ i18nKeys.FormLabels.IsRequired | translate }}
						</div>
					</ng-container>
				</cjm-input-wrapper>
			</fieldset>
		</fieldset>
		@if (mainActivities?.length > 0) {
			<fieldset class="c-vloket-form__fieldset c-vloket-form__fieldset__extend-bottom-spacing">
				<legend class="c-vloket-header c-vloket-header--h4">
					<span class="c-vloket-text-marking c-vloket-text-marking--underline">{{
						i18nKeys.Registration.Register.BasicData.GeneralFieldset.ActivitiesTitle | translate
					}}</span>
				</legend>
				<vloket-checkbox-group
					formControlName="mainActivities"
					[data]="mainActivities"
					[minAmount]="1"
					[maxAmount]="3"
					[hintLabel]="i18nKeys.FormLabels.SelectMinMaxValues | translate: { min: 1, max: 3 }"
					[minAmountLabel]="i18nKeys.FormLabels.SelectMinOneValue | translate"
					[maxAmountLabel]="i18nKeys.FormLabels.SelectMaxValues | translate: { max: 3 }"
				/>
			</fieldset>
		}
		<fieldset class="c-vloket-form__fieldset">
			<legend class="c-vloket-header c-vloket-header--h4">
				<span class="c-vloket-text-marking c-vloket-text-marking--underline">
					{{ i18nKeys.Registration.Register.BasicData.CorrespondenceFieldset.Title | translate }}
				</span>
			</legend>
			<vloket-location-form
				formControlName="associationAddress"
				[showDescription]="false"
				[showLocationType]="false"
			/>
		</fieldset>
	</fieldset>
}
