import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { delay, filter, takeUntil, tap } from 'rxjs/operators';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { OnDestroyComponent } from '@cjm/shared/types';
import { LoaderComponent } from '@cjm/shared/ui/common';

import { IPostRegistrationResponseEntity, RedirectRegistrationFacade } from '../../../data';

@Component({
	templateUrl: './redirect-registration.component.html',
	styleUrls: ['./redirect-registration.component.scss'],
	providers: [RedirectRegistrationFacade],
	standalone: true,
	imports: [LoaderComponent]
})
export class RedirectRegistrationComponent extends OnDestroyComponent implements OnInit {
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;

	constructor(
		private readonly redirectRegistrationFacade: RedirectRegistrationFacade,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) {
		super();
	}

	public ngOnInit(): void {
		combineLatest([
			this.redirectRegistrationFacade.hasRegistrationConflictResponse$,
			this.redirectRegistrationFacade.registrationResponse$
		])
			.pipe(
				delay(500),
				tap(([hasConflict]: [boolean, IPostRegistrationResponseEntity]) => {
					if (hasConflict) {
						this.router.navigate(['..', this.appRoutePaths.RegistrationDuplicateCheck], {
							relativeTo: this.route
						});
					}
				}),
				filter(([hasConflict]: [boolean, IPostRegistrationResponseEntity]) => !hasConflict),
				tap(([_, registrationData]: [boolean, IPostRegistrationResponseEntity]) => {
					this.router.navigate(
						['..', this.appRoutePaths.RegistrationSuccess, registrationData.verenigingsnummer],
						{
							relativeTo: this.route
						}
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
