import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeBe from '@angular/common/locales/nl-BE';
import { enableProdMode, LOCALE_ID, ErrorHandler, importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { I18nService, NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { NgxTableConfigToken } from '@studiohyperdrive/ngx-table';
import { WITH_ROUTER_LINKS_CONFIG, WithRouterLinksConfig } from '@studiohyperdrive/ngx-utils';
import { CookieModule } from 'ngx-cookie';
import { provideMatomo, withRouter } from 'ngx-matomo-client';

import { AuthDataModule } from '@cjm/shared/authentication/auth';
import { CompanyDataModule } from '@cjm/shared/company';
import { ErrorService, SharedCoreModule } from '@cjm/shared/core';
import { NotificationsDataModule } from '@cjm/shared/notifications';
import { ProductDataModule } from '@cjm/shared/products';
import { StatusModule } from '@cjm/shared/status';
import { StoreSlice } from '@cjm/shared/store';
import { AbstractI18nService, Language } from '@cjm/shared/types';
import { ModalDataModule } from '@cjm/shared/ui/modal';
import { UserDataModule, UserModule } from '@cjm/shared/user';
import { ConfirmationTokenInterceptor } from '@cjm/shared/utils';
import { reducers } from '@cjm/v-loket/registration';
import { environment } from '~environment';

import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app.routing';
import { VLoketTranslationLoader } from './app/translation.loader';

if (environment.production) {
	enableProdMode();
}

registerLocaleData(localeBe);

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			AppRoutingModule,
			UserDataModule,
			AuthDataModule,
			UserModule.forRoot(),
			StoreModule.forRoot([]),
			// Denis: provide the registration store here to support the KBO registration modal:
			StoreModule.forFeature(StoreSlice.Registration, reducers),
			StoreDevtoolsModule.instrument({
				maxAge: 25,
				connectInZone: true
			}),
			NgxI18nModule.forRoot(
				{
					defaultLanguage: Language.NL,
					availableLanguages: [Language.NL, Language.EN],
					defaultAssetPaths: ['./assets/i18n/shared/i18n/']
				},
				VLoketTranslationLoader
			),
			SharedCoreModule,
			StatusModule,
			ProductDataModule,
			CookieModule.forRoot(),
			ServiceWorkerModule.register('ngsw-worker.js', {
				enabled: environment.hasServiceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 10 seconds (whichever comes first).
				registrationStrategy: 'registerWhenStable:10000'
			}),
			CompanyDataModule,
			NotificationsDataModule,
			ModalDataModule
		),
		{
			provide: LOCALE_ID,
			useValue: 'nl-BE'
		},
		{
			provide: AbstractI18nService,
			useClass: I18nService
		},
		{
			provide: ErrorHandler,
			useClass: ErrorService
		},
		{ provide: LOCALE_ID, useValue: 'nl-BE' },
		{
			provide: NgxTableConfigToken,
			useValue: {
				ngxTableClass: 'cjm-table',
				emitValueOnSingleItem: false
			}
		},
		provideMatomo(
			{
				trackerUrl: environment.matomo.trackerUrl,
				siteId: environment.matomo.siteId
			},
			withRouter()
		),
		{
			provide: WITH_ROUTER_LINKS_CONFIG,
			useValue: {
				replaceElementSelector: 'vlo-link',
				linkAttributeName: 'to',
				dataLinkIdAttributeName: 'data-link-id'
			} as WithRouterLinksConfig
		},
		{ provide: HTTP_INTERCEPTORS, useClass: ConfirmationTokenInterceptor, multi: true },
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations()
	]
}).catch((err) => console.error(err));
