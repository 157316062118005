import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableArray, ObservableNumber } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';

import {
	EidRedirectWarning,
	UserEIDIdentitySwitchDirective,
	UserEIDLoginDirective
} from '@cjm/shared/authentication/auth';
import { MetaService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { ACMTargetGroups } from '@cjm/shared/types';
import { BreadcrumbService } from '@cjm/shared/ui/breadcrumb';
import {
	AlertType,
	ButtonClasses,
	HideMenuComponent,
	HideMenuService,
	AlertComponent,
	ButtonComponent,
	LayoutContainerComponent
} from '@cjm/shared/ui/common';
import {
	AssociationType,
	IconListItem,
	AssociationCardComponent,
	IconListComponent,
	ProgressBarComponent
} from '@cjm/v-loket/shared';

import { IRegisterAssociationData, SuccessFacade } from '../../../data';
import { I18nKeys } from '../../../i18n';

@Component({
	templateUrl: './success.component.html',
	providers: [SuccessFacade],
	styleUrls: ['./success.component.scss'],
	standalone: true,
	imports: [
		LayoutContainerComponent,
		AlertComponent,
		AssociationCardComponent,
		ProgressBarComponent,
		IconListComponent,
		ButtonComponent,
		UserEIDLoginDirective,
		UserEIDIdentitySwitchDirective,
		AsyncPipe,
		TranslateModule
	]
})
export class SuccessPageComponent extends HideMenuComponent implements OnInit, OnDestroy {
	public readonly associationData$: Observable<IRegisterAssociationData> = this.successFacade.associationData$;
	public readonly myAssociationPercentage$: ObservableNumber = this.successFacade.myAssociationPercentage$;
	public readonly myAssociationProgress$: ObservableArray<IconListItem> = this.successFacade.myAssociationProgress$;

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly alertType: typeof AlertType = AlertType;
	public readonly associationTypes: typeof AssociationType = AssociationType;
	public readonly acmTargetGroups: typeof ACMTargetGroups = ACMTargetGroups;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly associationDetailsCallbackUrl: string = `/${this.i18nService.currentLanguage}/${this.appRoutePaths.MyAssociation}/${this.appRoutePaths.AssociationData}`;
	public readonly ProductsCallbackUrl: string = `/${this.i18nService.currentLanguage}/${this.appRoutePaths.Offers}`;
	public readonly authRedirectFVDataWarning: EidRedirectWarning = {
		title: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.AutoLoginTitle),
		text: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.TextEditFVData),
		confirmLabel: this.i18nService.getTranslation(
			this.i18nKeys.Registration.Success.CtaButtons.Notice.ConfirmLabel
		),
		cancelLabel: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.CancelLabel)
	};
	public readonly authRedirectFVProductsWarning: EidRedirectWarning = {
		title: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.AutoLoginTitle),
		text: this.i18nService.getTranslation(
			this.i18nKeys.Registration.Success.CtaButtons.Notice.TextSearchFVProducts
		),
		confirmLabel: this.i18nService.getTranslation(
			this.i18nKeys.Registration.Success.CtaButtons.Notice.ConfirmLabel
		),
		cancelLabel: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.CancelLabel)
	};
	public readonly authRedirectKBODataWarning: EidRedirectWarning = {
		title: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.ManualLoginTitle),
		text: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.TextEditKBOData),
		confirmLabel: this.i18nService.getTranslation(
			this.i18nKeys.Registration.Success.CtaButtons.Notice.ConfirmLabel
		),
		cancelLabel: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.CancelLabel)
	};
	public readonly authRedirectKBOProductsWarning: EidRedirectWarning = {
		title: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.ManualLoginTitle),
		text: this.i18nService.getTranslation(
			this.i18nKeys.Registration.Success.CtaButtons.Notice.TextSearchKBOProducts
		),
		confirmLabel: this.i18nService.getTranslation(
			this.i18nKeys.Registration.Success.CtaButtons.Notice.ConfirmLabel
		),
		cancelLabel: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.CtaButtons.Notice.CancelLabel)
	};

	constructor(
		private readonly successFacade: SuccessFacade,
		private readonly i18nService: I18nService,
		private readonly breadcrumbService: BreadcrumbService,
		private readonly metaService: MetaService,
		private readonly router: Router,
		protected readonly hideMenuService: HideMenuService
	) {
		super(hideMenuService);
	}

	public ngOnInit(): void {
		this.metaService.updateMetaData(
			{
				title: this.i18nService.getTranslation(this.i18nKeys.PageTitles.VLoket.SuccessRegistration),
				description: this.i18nService.getTranslation(this.i18nKeys.Registration.Success.MetaDescription),
				pageUrl: this.router.url,
				imageUrl: { path: 'jeugd.png' }
			},
			// Abdurrahman: this page shouldn't be indexed
			false
		);

		this.breadcrumbService.setBreadcrumbs(
			[
				{
					routePath: [],
					title: this.i18nKeys.PageTitles.VLoket.SuccessRegistration,
					allowNav: false
				}
			],
			true
		);
		super.ngOnInit();
	}

	public ngOnDestroy(): void {
		this.breadcrumbService.clearBreadcrumbs();
		super.ngOnDestroy();
	}
}
