<div class="c-app-wrapper" [ngClass]="{ 'c-app-wrapper--with-menu-padding': showMenu() }">
	<!--
		Topbar

		This contains both the global header (ACM) as the global navigation.

		---
		Notes
		---

		Denis: The menu can be hidden on certain pages through the `showMenu()` signal.
		This signal connects to the menu service.
	-->
	<header class="topbar">
		<div class="c-header__block c-header__container">
			<cjm-acm-header cypressTag="Header.Header" />
		</div>

		@if (showMenu()) {
			<cjm-layout-container
				class="c-header__block"
				layoutType="full-width"
				[preventSpacing]="['top', 'bottom']"
				[preserve]="true"
			>
				<cjm-menu
					main
					[menuLabel]="i18nKey.Common.Menu | translate"
					[menuItems]="primaryMenuItems"
					[secondaryMenuItems]="secondaryMenuItems"
					(toggled)="menuToggle($event)"
				>
					<ng-template #itemTmpl let-item>
						{{ item | translate }}
					</ng-template>
				</cjm-menu>
			</cjm-layout-container>
		}
	</header>

	<main>
		<!--
			Sidebar

			This element contains the top level breadcrumb and the main router outlet.

			---
			Notes
			---

			Denis: `inert` allows us to make this element and all children unavailable when the menu is open.
			This is done to assure that the keyboard-accessible or screenreader version of the application reflects
			the visual state of the application. All elements behind the backdrop are not accessible.

			Setting the attribute to null makes sure Angular removes it altogether.

			(https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert)
		-->
		<cjm-sidebar [attr.inert]="menuToggled() ? true : null">
			<cjm-breadcrumb class="c-vloket-breadcrumb" />

			<router-outlet></router-outlet>
		</cjm-sidebar>
	</main>

	<footer>
		<!--
			Big Footer

			The big footer is the grey area in the footer where quick links are provided to the user.

			---
			Notes
			---

			Denis: `inert` allows us to make this element and all children unavailable when the menu is open.
			This is done to assure that the keyboard-accessible or screenreader version of the application reflects
			the visual state of the application. All elements behind the backdrop are not accessible.

			Setting the attribute to null makes sure Angular removes it altogether.

			(https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert)
		-->
		<cjm-vloket-big-footer [attr.inert]="menuToggled() ? true : null" [data]="bigFooterItems" />

		<!--
			Footer

			The footer contains the ACM footer.

			---
			Notes
			---

			Denis: `inert` allows us to make this element and all children unavailable when the menu is open.
			This is done to assure that the keyboard-accessible or screenreader version of the application reflects
			the visual state of the application. All elements behind the backdrop are not accessible.

			Setting the attribute to null makes sure Angular removes it altogether.

			(https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert)
		-->
		<cjm-vloket-footer [attr.inert]="menuToggled() ? true : null" (openCookieConsent)="openCookiesPopup()" />
	</footer>
</div>
