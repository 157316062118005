import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedCompanyI18nKeys } from '@cjm/shared/company';
import { sharedI18nKeys } from '@cjm/shared/i18n';
import { I18nKeys as formsI18nKeys } from '@cjm/shared/ui/forms';

export const I18nKeys = {
	...sharedI18nKeys,
	...formsI18nKeys,
	...sharedCompanyI18nKeys,
	Registration: {
		Start: {
			Title: marker('registration.start.title'),
			Intro: marker('registration.start.intro'),
			TimeNotice: marker('registration.start.time-notice'),
			Association: {
				Title: marker('registration.start.association.title'),
				BodyHTML: marker('registration.start.association.bodyHTML'),
				Button: {
					Text: marker('registration.start.association.button.text'),
					Title: marker('registration.start.association.button.title')
				},
				Characteristics: marker('registration.start.association.characteristics'),
				MoreInfoLink: {
					AccessibleTitle: marker('registration.start.association.more-info-link.accessible-title')
				}
			},
			Foundation: {
				Title: marker('registration.start.foundation.title'),
				BodyHTML: marker('registration.start.foundation.bodyHTML'),
				Button: {
					Text: marker('registration.start.foundation.button.text'),
					Title: marker('registration.start.foundation.button.title')
				},
				Characteristics: marker('registration.start.foundation.characteristics'),
				MoreInfoLink: {
					Title: marker('registration.start.association.more-info-link.title'),
					AccessibleTitle: marker('registration.start.association.more-info-link.accessible-title')
				}
			},
			Department: {
				Title: marker('registration.start.department.title'),
				BodyHTML: marker('registration.start.department.bodyHTML'),
				Button: {
					Text: marker('registration.start.department.button.text'),
					Title: marker('registration.start.department.button.title')
				},
				MoreInfoLink: {
					Text: marker('registration.start.department.more-info-link.text'),
					Title: marker('registration.start.department.more-info-link.title')
				}
			},
			NotQuiteSure: {
				Title: marker('registration.start.not-quite-sure.title'),
				BodyHTML: marker('registration.start.not-quite-sure.bodyHTML'),
				MoreInfoLink: {
					Text: marker('registration.start.not-quite-sure.more-info-link.text'),
					Title: marker('registration.start.not-quite-sure.more-info-link.title')
				}
			},
			Notice: {
				AutoLoginTitle: marker('registration.start.notice.auto-login-title'),
				TextRegisterFV: marker('registration.start.notice.text-register-fv'),
				ConfirmLabel: marker('registration.start.notice.confirm-label'),
				CancelLabel: marker('registration.start.notice.cancel-label')
			}
		},
		DuplicateCheck: {
			Title: marker('registration.duplicate-check.title'),
			MetaDescription: marker('registration.duplicate-check.meta-description'),
			Intro: marker('registration.duplicate-check.intro'),
			AvoidDuplicatesNotice: marker('registration.duplicate-check.avoid-duplicate-notice'),
			LostContextNotice: marker('registration.duplicate-check.lost-context-notice'),
			EditCTA: {
				Text: marker('registration.duplicate-check.edit-CTA.text'),
				Title: marker('registration.duplicate-check.edit-CTA.title')
			},
			ResultInidicator: marker('registration.duplicate-check.result-indicator'),
			ResultsInidicator: marker('registration.duplicate-check.results-indicator'),
			RequestMaintainershipCTA: {
				Text: marker('registration.duplicate-check.request-maintainership-CTA.text'),
				Title: marker('registration.duplicate-check.request-maintainership-CTA.title')
			},
			CreateCTA: {
				Text: marker('registration.duplicate-check.create-CTA.text'),
				Title: marker('registration.duplicate-check.create-CTA.title')
			},
			Notifications: {
				Title: marker('registration.duplicate-check.notifications.title'),
				Success: marker('registration.duplicate-check.notifications.success')
			},
			ToolTip: marker('registration.duplicate-check.tooltip'),
			Notices: {
				CustomError: {
					Message: marker('registration.duplicate-check.notices.custom-error.message')
				}
			}
		},
		Register: {
			Title: marker('registration.register.title'),
			BasicData: {
				Title: marker('registration.register.basicData.title'),
				Description: marker('registration.register.basicData.description'),
				GeneralFieldset: {
					Title: marker('registration.register.basicData.generalFieldset.title'),
					AssociationNameField: {
						Label: marker('registration.register.basicData.generalFieldset.associationNameField.label'),
						Placeholder: marker(
							'registration.register.basicData.generalFieldset.associationNameField.placeholder'
						)
					},
					ActivitiesTitle: marker('registration.register.basicData.generalFieldset.activities-title')
				},
				CorrespondenceFieldset: {
					Title: marker('registration.register.basicData.correspondenceFieldset.title'),
					AssociationAddressPostcodeField: {
						Label: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressPostcodeField.label'
						),
						Placeholder: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressPostcodeField.placeholder'
						)
					},
					AssociationAddressCountryField: {
						Label: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressCountryField.label'
						),
						Placeholder: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressCountryField.placeholder'
						)
					},
					AssociationAddressStreetField: {
						Label: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressStreetField.label'
						),
						Placeholder: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressStreetField.placeholder'
						)
					},
					AssociationAddressNumberField: {
						Label: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressNumberField.label'
						),
						Placeholder: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressNumberField.placeholder'
						)
					},
					AssociationAddressAdditionField: {
						Label: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressAdditionField.label'
						),
						Placeholder: marker(
							'registration.register.basicData.correspondenceFieldset.associationAddressAdditionField.placeholder'
						)
					}
				}
			},
			ContactData: {
				Title: marker('registration.register.contactData.title'),
				Description: marker('registration.register.contactData.description'),
				CorrespondenceInfoFieldset: {
					Title: marker('registration.register.contactData.correspondenceInfoFieldset.title'),
					AssociationPhoneField: {
						Label: marker(
							'registration.register.contactData.correspondenceInfoFieldset.associationPhoneField.label'
						),
						Placeholder: marker(
							'registration.register.contactData.correspondenceInfoFieldset.associationPhoneField.placeholder'
						)
					},
					AssociationEmailField: {
						Label: marker(
							'registration.register.contactData.correspondenceInfoFieldset.associationEmailField.label'
						),
						Placeholder: marker(
							'registration.register.contactData.correspondenceInfoFieldset.associationEmailField.placeholder'
						)
					},
					AssociationWebsiteField: {
						Label: marker(
							'registration.register.contactData.correspondenceInfoFieldset.associationWebsiteField.label'
						),
						Placeholder: marker(
							'registration.register.contactData.correspondenceInfoFieldset.associationWebsiteField.placeholder'
						)
					}
				}
			},
			AdditionalData: {
				Title: marker('registration.register.additionalData.title'),
				Description: marker('registration.register.additionalData.description'),
				AssociationBackgroundFieldset: {
					Title: marker('registration.register.additionalData.associationBackgroundFieldset.title'),
					AssociationCallname: {
						Label: marker(
							'registration.register.additionalData.associationBackgroundFieldset.associationCallname.label'
						),
						Placeholder: marker(
							'registration.register.additionalData.associationBackgroundFieldset.associationCallname.placeholder'
						)
					},
					AssociationDescription: {
						Label: marker(
							'registration.register.additionalData.associationBackgroundFieldset.associationDescription.label'
						),
						Placeholder: marker(
							'registration.register.additionalData.associationBackgroundFieldset.associationDescription.placeholder'
						)
					},
					AssociationFoundingDate: {
						Label: marker(
							'registration.register.additionalData.associationBackgroundFieldset.associationFoundingDate.label'
						),
						Placeholder: marker(
							'registration.register.additionalData.associationBackgroundFieldset.associationFoundingDate.placeholder'
						)
					}
				}
			},
			RepresentativeData: {
				Title: marker('registration.register.representativeData.title'),
				Tooltip: marker('registration.register.representativeData.tooltip'),
				Description: marker('registration.register.representativeData.description'),
				YourDataTitle: marker('registration.register.representativeData.your-data-title'),
				Actions: {
					AddRepresentative: {
						Text: marker('registration.register.representativeData.actions.addRepresentative.text'),
						Title: marker('registration.register.representativeData.actions.addRepresentative.title'),
						Tooltip: marker('registration.register.representativeData.actions.addRepresentative.tooltip')
					},
					RemoveRepresentative: {
						Text: marker('registration.register.representativeData.actions.removeRepresentative.text'),
						Title: marker('registration.register.representativeData.actions.removeRepresentative.title')
					}
				}
			},
			NewsLetterData: {
				Title: marker('registration.register.newsLetterData.title'),
				Description: marker('registration.register.newsLetterData.description'),
				SubscribeToNewsletter: {
					Title: marker('registration.register.newsLetterData.subscribeToNewsletter.title'),
					Checkbox: {
						Title: marker('registration.register.newsLetterData.subscribeToNewsletter.checkbox.title'),
						Text: marker('registration.register.newsLetterData.subscribeToNewsletter.checkbox.text')
					}
				}
			},
			FormActions: {
				GoBack: marker('registration.register.formActions.goBack'),
				RegisterAssociation: {
					Text: marker('registration.register.formActions.registerAssociation.text'),
					Title: marker('registration.register.formActions.registerAssociation.title')
				}
			},
			FormNotice: {
				InvalidFormTitle: marker('registration.register.formNotice.invalidFormTitle'),
				InvalidForm: marker('registration.register.formNotice.invalidForm'),
				Invites: {
					Title: marker('registration.register.formNotice.invites.title'),
					Success: marker('registration.register.formNotice.invites.success'),
					FailedInvites: marker('registration.register.formNotice.invites.failed-invites'),
					FailedCopies: marker('registration.register.formNotice.invites.failed-copies')
				}
			},
			Error: {
				Title: marker('registration.register.error.title')
			}
		},
		RegisterEa: {
			Title: marker('registration.register-ea.title'),
			BasicData: {
				Title: marker('registration.register-ea.basicData.title'),
				Description: marker('registration.register-ea.basicData.description'),
				GeneralFieldset: {
					Title: marker('registration.register-ea.basicData.generalFieldset.title'),
					AssociationNameField: marker(
						'registration.register-ea.basicData.generalFieldset.associationNameField.label'
					),
					ActivitiesTitle: marker('registration.register-ea.basicData.generalFieldset.activities-title')
				}
			},
			RepresentativeData: {
				Title: marker('registration.register-ea.representative-data.title'),
				Notice: marker('registration.register-ea.representative-data.notice')
			},
			AdditionalData: {
				Title: marker('registration.register-ea.additionalData.title'),
				Description: marker('registration.register-ea.additionalData.description'),
				AssociationBackgroundFieldset: {
					Title: marker('registration.register-ea.additionalData.associationBackgroundFieldset.title'),
					Description: marker(
						'registration.register-ea.additionalData.associationBackgroundFieldset.description'
					),
					AssociationCallname: {
						Label: marker(
							'registration.register-ea.additionalData.associationBackgroundFieldset.associationCallname.label'
						),
						Placeholder: marker(
							'registration.register-ea.additionalData.associationBackgroundFieldset.associationCallname.placeholder'
						)
					},
					AssociationDescription: {
						Label: marker(
							'registration.register-ea.additionalData.associationBackgroundFieldset.associationDescription.label'
						),
						Placeholder: marker(
							'registration.register-ea.additionalData.associationBackgroundFieldset.associationDescription.placeholder'
						)
					}
				}
			},
			FormActions: {
				GoBack: marker('registration.register-ea.formActions.goBack'),
				RegisterAssociation: {
					Text: marker('registration.register-ea.formActions.registerAssociation.text'),
					Title: marker('registration.register-ea.formActions.registerAssociation.title')
				}
			},
			FormNotice: {
				InvalidFormTitle: marker('registration.register-ea.formNotice.invalidFormTitle'),
				InvalidForm: marker('registration.register-ea.formNotice.invalidForm')
			}
		},
		RegisterEaModal: {
			Title: marker('registration.register-ea-modal.title'),
			Description: marker('registration.register-ea-modal.description'),
			MainActivities: {
				Title: marker('registration.register-ea-modal.main-activities.title')
			},
			NotNowButton: {
				Text: marker('registration.register-ea-modal.not-now-button.text'),
				Title: marker('registration.register-ea-modal.not-now-button.title')
			}
		},
		Success: {
			Title: {
				Association: marker('registration.success.title.association'),
				KBOAssociation: marker('registration.success.title.kbo-association')
			},
			MetaDescription: marker('registration.success.meta-description'),
			BodyHTML: marker('registration.success.bodyHTML'),
			DelayNotice: marker('registration.success.delay-notice'),
			ProgressBarTitle: marker('registration.success.progressBarTitle'),
			CtaButtons: {
				ToMyAssociations: {
					Title: marker('registration.success.ctaButtons.toMyAssociations.title'),
					Text: marker('registration.success.ctaButtons.toMyAssociations.text')
				},
				ToProducts: {
					Title: marker('registration.success.ctaButtons.toProducts.title'),
					Text: marker('registration.success.ctaButtons.toProducts.text')
				},
				Notice: {
					AutoLoginTitle: marker('registration.success.ctaButtons.notice.auto-login-title'),
					ManualLoginTitle: marker('registration.success.ctaButtons.notice.manual-login-title'),
					TextEditFVData: marker('registration.success.ctaButtons.notice.text-edit-fv-data'),
					TextSearchFVProducts: marker('registration.success.ctaButtons.notice.text-search-fv-products'),
					TextEditKBOData: marker('registration.success.ctaButtons.notice.text-edit-kbo-data'),
					TextSearchKBOProducts: marker('registration.success.ctaButtons.notice.text-search-kbo-products'),
					ConfirmLabel: marker('registration.success.ctaButtons.notice.confirm-label'),
					CancelLabel: marker('registration.success.ctaButtons.notice.cancel-label')
				}
			}
		}
	}
};
