import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IRegistration } from '../../../ui';
import { IPostRegistrationConflictResponse, PostRegistrationResponseEntity } from '../../interfaces';
import { selectors } from '../../store/registration.store';

@Injectable()
export class RedirectRegistrationFacade extends StoreService {
	/**
	 * This observable exposes the conflict response when a registration results in a positive duplicate check.
	 */
	public readonly hasRegistrationConflictResponse$: ObservableBoolean =
		this.selectErrorMessageFromStore<IPostRegistrationConflictResponse>(selectors.postRegistrationResponse).pipe(
			// Denis: Check if the error response is a conflict response
			map((error: IPostRegistrationConflictResponse) => {
				return error && error['@type'] === 'VerenigingscreatieConflict';
			})
		);
	public readonly registrationResponse$: Observable<PostRegistrationResponseEntity> = this.selectFromStore(
		selectors.postRegistrationResponse
	);
	public readonly registrationFormData$: Observable<IRegistration> = this.selectFromStore(selectors.registerFormData);

	constructor(protected readonly store: Store) {
		super(store);
	}
}
