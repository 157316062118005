// Imports
// -------------------------------------------------------------------------- /
import { Component, OnInit, Renderer2 } from '@angular/core';
import { bootstrap, widget as acmWidget, session as acmSession } from '@govflanders/vl-widget-client/dist';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { combineLatest, from, Observable } from 'rxjs';
import { map, share, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { AuthenticationService } from '@cjm/shared/authentication/auth';
import { BrowserService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { UserService, UserEntity } from '@cjm/shared/user';
import { environment } from 'environments';

// Decorators
// -------------------------------------------------------------------------- /
@Component({
	selector: 'cjm-acm-header',
	templateUrl: './acm-header.component.html',
	standalone: true
})

// Class Definition
// -------------------------------------------------------------------------- /
export class AcmHeaderComponent implements OnInit {
	// Private properties
	// -------------------------------------------------------------------------- /
	private readonly widgetUrl = environment.acmidm.widgetsUrl + 'api/v1/widget/' + environment.acmidm.headerWidgetId;
	private session$: Observable<acmSession>;

	// Dependency Injection
	// -------------------------------------------------------------------------- /
	constructor(
		private readonly renderer: Renderer2,
		private readonly userService: UserService,
		private readonly browserService: BrowserService,
		private readonly authService: AuthenticationService,
		private readonly i18nService: I18nService
	) {}

	// Public methods
	// -------------------------------------------------------------------------- /

	private setupWidgetSession(): Observable<acmSession> {
		// Iben: Create an instance of the widget session
		return from(bootstrap(this.widgetUrl)).pipe(
			tap((widget: acmWidget) => {
				this.browserService.runInBrowser(({ browserDocument }) => {
					const element = browserDocument.getElementById('o-acm-header');
					widget.setMountElement(element).mount();

					// Denis: see documentation here: https://vlaamseoverheid.atlassian.net/wiki/spaces/IKPubliek/pages/6508874105/Aanmeldmenu
					widget.getExtension('citizen_profile').then((extension) => {
						// Possible menu groups: 'application' | 'primary' | 'secondary'
						extension
							.getMenu()
							.getGroup('application')
							.add({
								type: 'link',
								// Denis: the lack of a translation string is deliberate here.
								label: 'Registreer een vereniging',
								href: `/${this.i18nService.currentLanguage}/${VLoketAppRoutePaths.Registration}/${VLoketAppRoutePaths.RegistrationStart}`,
								icon: 'e-desk' // Also possible: e-desk-lock
							});
					});

					// Iben: Listen to the logoutRequest
					widget.on('citizen_profile.session.logout.request', (request) => {
						this.handleSessionLogOutRequest(request);
					});
				});
			}),
			switchMap((widget) => widget.getExtension('citizen_profile.session')),
			// Iben: Fetch the current user
			withLatestFrom(this.userService.user$),
			tap(([session, user]: [acmSession, UserEntity]) => {
				// Floris: Set the initial configuration.
				const url = environment.api.protocol + '://' + environment.api.hostname;
				session.configure({
					// Iben: If the user already exists we can add it as true when we create the session
					active: Boolean(user),
					endpoints: {
						loginUrl: `${url}${environment.acmidm.loginPath}${this.authService.getLocalRedirectUrl('?')}`,
						// Floris: Vervang door event.
						loginRedirectUrl:
							environment.domain +
							`${this.i18nService.currentLanguage}/${
								VLoketAppRoutePaths.Loket
							}${this.authService.getLocalRedirectUrl('?')}`,
						logoutUrl: `${url}${environment.acmidm.logoutPath}${this.authService.getLocalRedirectUrl('?')}`,
						switchCapacityUrl: `${url}${
							environment.acmidm.switchPath
						}${this.authService.getLocalRedirectUrl('?')}`
					}
				});

				// Floris: Extend the session on certain events.
				this.browserService.runInBrowser(() => {
					this.renderer.listen('document', 'click', () => {
						session.extend();
					});
					this.renderer.listen('document', 'keypress', () => {
						session.extend();
					});
				});
			}),
			// Iben: Map back to the actual session
			map(([session]) => session),
			share()
		);
	}

	private handleUserAuthentication(): Observable<[acmSession, UserEntity]> {
		return combineLatest([this.session$, this.userService.user$]).pipe(
			tap(([session, user]) => {
				const isAuthenticated = Boolean(user);

				session.configure({
					active: isAuthenticated
				});
			})
		);
	}

	private handleButtons(): Observable<acmSession> {
		return this.session$.pipe(
			take(1),
			tap((session) => {
				// Move this into the cjmUserEIDLogin directive.
				this.browserService.runInBrowser(({ browserDocument }) => {
					const buttons = browserDocument.getElementsByClassName('acm-login');

					for (let i = 0; i < buttons.length; i++) {
						buttons.item(i).addEventListener('click', (event) => {
							session.login();
							event.preventDefault();
						});
					}
				});
			})
		);
	}

	private handleSessionLogOutRequest(logOutRequest: any) {
		// Iben: Acknowledge the logout request
		logOutRequest.acknowledge();

		// Iben: Drop the authentication cookies
		this.authService.dropAuthentication();

		// Iben: Log ACM requests in case the feature is toggled
		this.browserService.runInBrowser(() => {
			if (localStorage.getItem('cjm.logACMLogoutRequests')) {
				console.log(`ACM Logout request: ${logOutRequest.getRequest().getReason()}`);
			}
		});

		// Iben: Logout the user
		if (logOutRequest.getRequest().getReason() === 'inactivity') {
			this.authService
				.logout()
				.pipe(tap(() => location.reload()))
				.subscribe();
		}

		// Iben: Accept the logout request
		logOutRequest.accept();
	}

	// Implementations
	// ------------------------------------------------------------------------ /
	public ngOnInit() {
		// Iben: Create a session
		this.session$ = this.setupWidgetSession();

		// Iben: Handle the authenticated state of the session
		this.handleUserAuthentication().subscribe();

		// Iben: Handle the event listeners
		this.handleButtons().subscribe();
	}
}
