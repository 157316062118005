import { Routes } from '@angular/router';
import { provideWithTranslations, TranslationLoaderGuard } from '@studiohyperdrive/ngx-i18n';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { HasRoleGuard, IsAuthenticatedGuard, IsRegisterableEAGuard } from '@cjm/shared/user';
import { AssociationApiService } from '@cjm/v-loket/repositories';

import { RegisterAPIService, RegisterEaAPIService, registerEaPageResolver, successPageResolver } from '../data';
import { RegistrationTranslationLoader } from '../i18n';

import {
	DuplicateCheckPageComponent,
	RegisterEaPageComponent,
	RegisterPageComponent,
	StartPageComponent,
	SuccessPageComponent,
	RedirectAuthenticationComponent,
	RedirectRegistrationComponent
} from './pages';

export const RegistrationRoutes: Routes = [
	provideWithTranslations(
		{
			path: VLoketAppRoutePaths.RegistrationStart,
			component: StartPageComponent,
			canActivate: [TranslationLoaderGuard]
		},
		RegistrationTranslationLoader
	),
	{
		path: `${VLoketAppRoutePaths.RegistrationRedirectAuthentication}/:type`,
		component: RedirectAuthenticationComponent
	},
	provideWithTranslations(
		{
			path: VLoketAppRoutePaths.RegistrationForm,
			component: RegisterPageComponent,
			canActivate: [TranslationLoaderGuard, IsAuthenticatedGuard, HasRoleGuard],
			data: {
				roles: ['CIVILIAN'],
				redirect: [VLoketAppRoutePaths.Redirects, VLoketAppRoutePaths.Stop]
			},
			providers: [
				// Services
				AssociationApiService,
				RegisterAPIService
			]
		},
		RegistrationTranslationLoader
	),
	provideWithTranslations(
		{
			path: VLoketAppRoutePaths.EARegistrationForm,
			component: RegisterEaPageComponent,
			canActivate: [TranslationLoaderGuard, IsAuthenticatedGuard, IsRegisterableEAGuard],
			data: {
				redirect: [VLoketAppRoutePaths.Redirects, VLoketAppRoutePaths.Stop]
			},
			resolve: {
				data: registerEaPageResolver
			},
			providers: [
				// Services
				AssociationApiService,
				RegisterEaAPIService
			]
		},
		RegistrationTranslationLoader
	),
	{
		path: VLoketAppRoutePaths.RegistrationRedirectRegistration,
		component: RedirectRegistrationComponent
	},
	provideWithTranslations(
		{
			path: VLoketAppRoutePaths.RegistrationDuplicateCheck,
			component: DuplicateCheckPageComponent,
			canActivate: [TranslationLoaderGuard, IsAuthenticatedGuard],
			providers: [
				// Services
				AssociationApiService,
				RegisterAPIService
			]
		},
		RegistrationTranslationLoader
	),
	provideWithTranslations(
		{
			path: `${VLoketAppRoutePaths.RegistrationSuccess}/:vCode`,
			component: SuccessPageComponent,
			canActivate: [TranslationLoaderGuard, IsAuthenticatedGuard],
			resolve: {
				data: successPageResolver
			},
			providers: [
				// Services
				AssociationApiService
			]
		},
		RegistrationTranslationLoader
	),
	{
		path: '**',
		redirectTo: VLoketAppRoutePaths.RegistrationStart
	}
];
