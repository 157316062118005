import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableArray, ObservableNumber, validateContent } from '@studiohyperdrive/rxjs-utils';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	I18nKeys as VLOI18nKeys,
	IconListItem,
	MyAssociationDetailProgressEntity,
	parseMyAssociationProgress,
	parseProgressTasksToIconListItems
} from '@cjm/v-loket/shared';

import { IRegisterAssociationData, PostRegistrationResponseEntity } from '../../interfaces';
import { selectors } from '../../store/registration.store';

@Injectable()
export class SuccessFacade extends StoreService {
	public readonly associationData$: Observable<IRegisterAssociationData> = this.selectFromStore(
		selectors.associationData
	);
	public readonly postRegistrationProgress$: Observable<MyAssociationDetailProgressEntity> = this.selectFromStore(
		selectors.postRegistrationResponse
	).pipe(
		validateContent({ strict: true }),
		map((response: PostRegistrationResponseEntity) => parseMyAssociationProgress(response.metadata?.compleetheid)),
		shareReplay()
	);
	public readonly myAssociationPercentage$: ObservableNumber = this.postRegistrationProgress$.pipe(
		validateContent({ strict: true }),
		map(({ percentage }: MyAssociationDetailProgressEntity) => {
			// Denis: The percentage is offered as a floating point number by the API.
			return Number((percentage * 100).toFixed(0));
		})
	);
	public readonly myAssociationProgress$: ObservableArray<IconListItem> = this.postRegistrationProgress$.pipe(
		validateContent({ strict: true }),
		map(({ tasks }: MyAssociationDetailProgressEntity): IconListItem[] => {
			const iconLabels = {
				done: this.i18nService.getTranslation(VLOI18nKeys.SharedAssociations.IconList.Alt.Done),
				todo: this.i18nService.getTranslation(VLOI18nKeys.SharedAssociations.IconList.Alt.Todo)
			};

			return parseProgressTasksToIconListItems(tasks, this.i18nService.currentLanguage, iconLabels, false);
		})
	);

	constructor(
		private readonly i18nService: I18nService,
		protected readonly store: Store
	) {
		super(store);
	}
}
