import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { CompanyEntity, CompanyService } from '@cjm/shared/company';
import { UserEntity, UserService } from '@cjm/shared/user';

export const registerEaPageResolver = (): Observable<CompanyEntity> => {
	const userService = inject(UserService);
	const companyService = inject(CompanyService);

	return userService.user$.pipe(
		filter((user: UserEntity) => !!user && !!user.company),
		switchMap(({ company }: UserEntity) => companyService.getCompany(company.number))
	);
};
