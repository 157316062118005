import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { STOP_CASES, STOP_CODES } from '@cjm/shared/redirects';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { OnDestroyComponent, UserTargetCodeEntity } from '@cjm/shared/types';
import { LoaderComponent } from '@cjm/shared/ui/common';
import { UserEntity, UserService } from '@cjm/shared/user';

@Component({
	templateUrl: './redirect-authentication.component.html',
	styleUrls: ['./redirect-authentication.component.scss'],
	standalone: true,
	imports: [LoaderComponent]
})
export class RedirectAuthenticationComponent extends OnDestroyComponent implements OnInit {
	constructor(
		private readonly userService: UserService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly i18nService: I18nService
	) {
		super();
	}

	public ngOnInit(): void {
		this.userService.loading$
			.pipe(
				filter((loading: boolean) => !loading),
				switchMap(() => this.userService.user$),
				tap((user: UserEntity) => {
					const selectedType: UserTargetCodeEntity = this.route.snapshot?.params?.type;
					const userType: UserTargetCodeEntity = user?.targetCode;
					const isNotRegistrable: boolean =
						user.targetCode === UserTargetCodeEntity.ECONOMIC_ACTOR &&
						!user.company?.hasOwnProperty('vCode');
					const hasVCode: boolean = typeof user?.company?.vCode === 'string' && user.company.vCode.length > 0;

					/**
					 * Denis
					 *
					 * If
					 * - the user is an EA
					 * - the selected type is EA
					 * - the user does not have a vCode
					 * - the user is registrable
					 */
					if (
						userType === UserTargetCodeEntity.ECONOMIC_ACTOR &&
						userType === selectedType &&
						!hasVCode &&
						!isNotRegistrable
					) {
						this.router.navigate([
							'/',
							this.i18nService.currentLanguage,
							VLoketAppRoutePaths.Registration,
							VLoketAppRoutePaths.EARegistrationForm
						]);

						return;
					}

					/**
					 * Denis
					 *
					 * If:
					 * - the user is a BUR
					 * - the selected type is BUR
					 */
					if (userType === UserTargetCodeEntity.CIVILIAN && userType === selectedType) {
						this.router.navigate([
							'/',
							this.i18nService.currentLanguage,
							VLoketAppRoutePaths.Registration,
							VLoketAppRoutePaths.RegistrationForm
						]);

						return;
					}

					/**
					 * Denis
					 *
					 * In other cases the user is not logged in with the correct claim,
					 * and they will be redirected to the stop page.
					 */
					this.router.navigate(
						[
							'/',
							this.i18nService.currentLanguage,
							VLoketAppRoutePaths.Redirects,
							VLoketAppRoutePaths.Stop
						],
						{
							queryParams: {
								cause: STOP_CODES.REGISTRATION,
								case: this.parseStopCase(selectedType, userType, isNotRegistrable, hasVCode)
							}
						}
					);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * parseStopCase
	 *
	 * The parseStopCase method will parse the stop case based on the selected type, user type, registrable status and vCode status.
	 *
	 * @param selectedType{UserTargetCodeEntity} - The selected type of the user in ACM.
	 * @param userType{UserTargetCodeEntity} - The type that was selected at the start registration.
	 * @param isNotRegistrable{boolean} - Is the organisation registrable.
	 * @param hasVCode{boolean} - Does the organisation have a vCode.
	 */
	public parseStopCase(
		selectedType: UserTargetCodeEntity,
		userType: UserTargetCodeEntity,
		isNotRegistrable: boolean,
		hasVCode: boolean
	): string {
		if (isNotRegistrable) {
			return STOP_CASES.EA_AS_NOT_REGISTRABLE;
		}

		if (selectedType === UserTargetCodeEntity.ECONOMIC_ACTOR && hasVCode) {
			return STOP_CASES.EA_AS_REGISTERED;
		}

		if (selectedType === UserTargetCodeEntity.ECONOMIC_ACTOR && userType === UserTargetCodeEntity.ASSOCIATION) {
			return STOP_CASES.EA_AS_FV;
		}

		if (selectedType === UserTargetCodeEntity.ECONOMIC_ACTOR && userType === UserTargetCodeEntity.CIVILIAN) {
			return STOP_CASES.EA_AS_BUR;
		}

		if (selectedType === UserTargetCodeEntity.CIVILIAN && userType !== UserTargetCodeEntity.CIVILIAN) {
			return STOP_CASES.FV_NOT_BUR;
		}

		return STOP_CASES.DEFAULT;
	}
}
