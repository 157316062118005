import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { catchAndCallThrough, ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AlertType } from '@cjm/shared/ui/common';
import { LoadingModalComponent, ModalDialogService } from '@cjm/shared/ui/modal';
import { catchWithSnackBar, SnackBarService } from '@cjm/shared/ui/toast';
import { UserEntity, UserService } from '@cjm/shared/user';
import { IMainActivity } from '@cjm/v-loket/repositories';

import { I18nKeys } from '../../../i18n';
import { IEARegistration } from '../../../ui';
import { PostRegistrationResponseEntity, IPostRegistrationConflictResponse } from '../../interfaces';
import { RegisterEaAPIService } from '../../services';
import { actions, selectors } from '../../store/registration.store';

@Injectable()
export class RegisterEaFacade extends StoreService {
	public readonly actionInProgressSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly actionInProgress$: ObservableBoolean = this.actionInProgressSubject$.asObservable();

	/**
	 * Loading state of the associations
	 */
	public readonly isRegistrationSubmitting$: ObservableBoolean = this.selectLoadingFromStore(
		selectors.postRegistrationResponse
	);

	/**
	 * This observable exposes the user info from the store through the UserService.
	 */
	public readonly user$: Observable<UserEntity> = this.userService.user$;

	/**
	 * This observable exposes the conflict response when a registration results in a positive duplicate check.
	 */
	public readonly registrationConflictResponse$: Observable<boolean> =
		this.selectErrorMessageFromStore<IPostRegistrationConflictResponse>(selectors.postRegistrationResponse).pipe(
			// Denis: Check if the error response is a conflict response
			map((error: IPostRegistrationConflictResponse) => error && error['@type'] === 'VerenigingscreatieConflict')
		);

	/**
	 * This observable exposes the current form data in the store.
	 */
	public readonly existingRegistrationData$: Observable<IEARegistration> = this.selectFromStore(
		selectors.registerFormData
	);

	private readonly i18nKeys: typeof I18nKeys = I18nKeys;

	constructor(
		private readonly registerEaAPIService: RegisterEaAPIService,
		private readonly userService: UserService,
		private readonly i18nService: I18nService,
		private readonly snackBarService: SnackBarService,
		protected readonly store: Store,
		private modalService: ModalDialogService
	) {
		super(store);
	}

	/**
	 * submitRegistration
	 *
	 * The submitRegistration method will dispatch two actions to the store:
	 * 1. The actions.eaRegisterFormData will set the form data to the store so that it can be used in a later step.
	 * 2. The actions.postRegistrationResponse along with the registerAPIService.postEaRegistrationData will perform the POST and store the response.
	 *
	 * @param data IEARegistration
	 * @Returns Observable:void
	 */
	public submitRegistration(data: IEARegistration): Observable<void> {
		this.actionInProgressSubject$.next(true);
		const modalRef = this.modalService.openModal(LoadingModalComponent).reference;

		this.store.dispatch(actions.postRegistrationResponse.clear());
		this.store.dispatch(actions.registerFormData.set({ payload: data }));

		return dispatchDataToStore<PostRegistrationResponseEntity>(
			actions.postRegistrationResponse,
			this.registerEaAPIService.postEaRegistrationData(data),
			this.store
		).pipe(
			catchAndCallThrough(() => {
				this.modalService.instantCloseModal(modalRef);
				this.actionInProgressSubject$.next(false);
			}, 'throw'),
			catchWithSnackBar(this.snackBarService, {
				title: this.i18nService.getTranslation(this.i18nKeys.Registration.Register.Error.Title),
				type: AlertType.Error
			}),
			tap(() => {
				this.actionInProgressSubject$.next(false);
			}),
			switchMap(() => this.modalService.closeModal(modalRef))
		);
	}

	/**
	 * getMainActivities
	 *
	 * The getMainActivities will request the main activities from the API through registerAPIService.getMainActivities.
	 *
	 * @Returns Observable:IGetMainActivitiesResponse
	 */
	public getMainActivities(): ObservableArray<IMainActivity> {
		return this.registerEaAPIService.getMainActivities();
	}
}
