import {
	NgxCookieCategories,
	NgxCookieConfiguration,
	NgxCookieLanguageConfiguration
} from '@studiohyperdrive/ngx-cookies/lib/types';

import { Language } from '@cjm/shared/i18n';

interface CookieConfig {
	categories: NgxCookieCategories;
	language: NgxCookieLanguageConfiguration;
	configuration?: NgxCookieConfiguration;
}

/**
 * Generates a cookie configuration object for ngxCookieService.
 *
 * @param language - The language for the cookie consent translation.
 * @param hideFromBots{boolean} - Whether to hide the cookie consent from bots.
 * @returns The generated cookie configuration object.
 */
export const generateCookieConfig = (
	language: Language = Language.NL,
	hideFromBots: boolean = false
): CookieConfig => ({
	// Categories of cookies
	categories: {
		necessary: {
			enabled: true, // this category is enabled by default
			readOnly: true, // this category cannot be disabled
			services: {
				cjm: {
					label: 'Noodzakelijke functionele cookies',
					cookies: [
						{
							name: 'cjm.authenticated'
						},
						{
							name: 'SESS*'
						},
						{
							name: 'cookieconsent_status'
						}
					]
				}
			}
		},
		analytics: {
			enabled: false,
			readOnly: false,
			services: {
				ma: {
					label: 'Analytische cookies',
					cookies: [
						{
							name: /^_pk_/ // regex: match all cookies starting with '_pk_'
						},
						{
							name: 'mtm_cookie_consent'
						}
					]
				}
			},
			autoClear: {
				reloadPage: true,
				cookies: [
					{
						name: /^_pk_/ // regex: match all cookies starting with '_pk_'
					}
				]
			}
		}
	},
	// Translation strings for the cookie consent
	language: {
		default: language,
		translations: {
			nl: '../assets/cookies/i18n/nl.json'
		}
	},
	// Configuration options. All config. options available here:
	// https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
	configuration: {
		hideFromBots,
		guiOptions: {
			consentModal: {
				layout: 'cloud',
				position: 'bottom left',
				equalWeightButtons: false,
				flipButtons: false
			},
			preferencesModal: {
				layout: 'box',
				equalWeightButtons: false,
				flipButtons: true
			}
		}
	}
});
